export let baseMenuItems = [
    {
        text: 'Dashboard',
        icon: 'icon-home4',
        routeName: 'Dashboard',
    },

    // {
    //     text: 'Messages',
    //     icon: 'icon-envelope',
    //     routeName: 'Messages',
    // },

    // {
    //     text: 'Mailing',
    //     icon: 'icon-envelope',
    //     routeName: 'Mailing',
    // },
    // {
    //     text: 'Bids',
    //     icon: 'icon-add-to-list ',
    //     routeName: 'Bids',
    // },

];

export let adminMenu = [
    // {
    //     text: 'Bids',
    //     icon: 'icon-add-to-list ',
    //     routeName: 'Bids',
    // },
    {
        text: 'Clients',
        icon: 'users',
        routeName: 'Clients',
    },
    // {
    //     text: 'System Users',
    //     icon: 'icon-person',
    //     routeName: 'SystemClients',
    // },
    {
        text: 'Writers',
        icon: 'users',
        routeName: 'Writers',
    },
    {
        text: 'Editors',
        icon: 'users',
        routeName: 'Editors',
    },
    {
        text: 'Sites',
        icon: 'globe',
        routeName: 'Sites',
    },
    // {
    //     text: 'Online Users',
    //     icon: 'icon-person',
    //     routeName: 'Profile',
    // },

    {
        text: 'Payments',
        icon: 'credit-card',
        routeName: 'Payments',
    },
    {
        text: 'Fines',
        icon: 'user-minus',
        routeName: 'Fines',
    },
    {
        text: 'Bonus',
        icon: 'user-plus',
        routeName: 'Bonus',
    },


];

export let setupsMenu = [
    {
        text: 'Connections',
        icon: 'wrench',
        routeName: 'Connections',
    },
    {
        text: 'Discounts',
        icon: 'tags',
        routeName: 'Discounts',
    },

    {
        text: 'Writer Levels',
        icon: 'layer-group',
        routeName: 'WriterLevels',
    },

    {
        text: 'Writer Preferences',
        icon: 'layer-group',
        routeName: 'WriterPreferences',
    },

    {
        text: 'Additional Services',
        icon: 'folder-plus',
        routeName: 'AdditionalServices',
    },

    {
        text: 'File Types',
        icon: 'folder',
        routeName: 'FileTypes',
    },
    {
        text: 'Styles',
        icon: 'layer-group',
        routeName: 'Styles',
    },
    {
        text: 'Academic Levels',
        icon: 'layer-group',
        routeName: 'AcademicLevels',
    },
    {
        text: 'Document Types',
        icon: 'layer-group',
        routeName: 'DocumentTypes',
    },

    {
        text: 'Language Styles',
        icon: 'layer-group',
        routeName: 'LanguageStyles',
    },

    {
        text: 'Subject Areas',
        icon: 'layer-group',
        routeName: 'SubjectAreas',
    },

    {
        text: 'Urgency',
        icon: 'layer-group',
        routeName: 'Urgency',
    },

    {
        text: 'Spacing',
        icon: 'layer-group',
        routeName: 'Spacing',
    },
    

    
];


export let reportsMenu = []
export let clientMenu = [
    {
        text: 'Bids',
        icon: 'cart-plus',
        routeName: 'Bids',
    },
    {
        text: 'Clients',
        icon: 'users',
        routeName: 'Clients',
    },
    {
        text: 'Sites',
        icon: 'globe',
        routeName: 'SystemUserSites',
    },
    // {
    //     text: 'Online Users',
    //     icon: 'icon-person',
    //     routeName: 'Profile',
    // },

    {
        text: 'Payments',
        icon: 'credit-card',
        routeName: 'Payments',
    },
    {
        text: 'Fines',
        icon: 'user-minus',
        routeName: 'Fines',
    },
    {
        text: 'Bonus',
        icon: 'user-plus',
        routeName: 'Bonus',
    },

    // {
    //     text: 'Discounts',
    //     icon: 'icon-stack-minus',
    //     routeName: 'Discounts',
    // },


];
export let writerMenu = [
    // {
    //     text: 'My Bids',
    //     icon: 'icon-add-to-list ',
    //     routeName: 'Bids',
    // },
    {
        text: 'Payments',
        icon: 'credit-card',
        routeName: 'Payments',
    },
    // {
    //     text: 'Payment Balance',
    //     icon: 'icon-stack-check',
    //     routeName: 'Balance',
    // },
    {
        text: 'Fines',
        icon: 'user-minus',
        routeName: 'Fines',
    },
    {
        text: 'Bonus',
        icon: 'user-plus',
        routeName: 'Bonus',
    },

];
export let editorMenu = [
    // {
    //     text: 'Bids',
    //     icon: 'icon-add-to-list ',
    //     routeName: 'Bids',
    // },
    // {
    //     text: 'My Earnings',
    //     icon: 'icon-stack-check ',
    //     routeName: 'Profile'
    // },

    {
        text: 'Writers',
        icon: 'users',
        routeName: 'Writers',
    },

    {
        text: 'Writer Levels',
        icon: 'layer-group',
        routeName: 'WriterLevels',
    },
    // {
    //     text: 'Writer Preferences',
    //     icon: 'icon-cog3',
    //     routeName: 'WriterPreferences',
    // },
    // {
    //     text: 'Additional Services',
    //     icon: 'icon-stack-plus',
    //     routeName: 'AdditionalServices',
    // },
    {
        text: 'Payments',
        icon: 'credit-card',
        routeName: 'Payments',
    },
    {
        text: 'Fines',
        icon: 'user-minus',
        routeName: 'Fines',
    },
    {
        text: 'Bonus',
        icon: 'user-plus',
        routeName: 'Bonus',
    },

    // {
    //     text: 'Payment Balance',
    //     icon: 'icon-stack-check',
    //     routeName: 'Balance',
    // },
];


// export let WriterLevels = [

//     {
//         text: 'Writer Levels',
//         icon: 'icon-cog3',
//         routeName: 'WriterLevels',
//     },

// ];

// export let actionMenuItems = [
//     {
//         text: 'Place Order',
//         icon: 'icon-add',
//         routeName: 'PlaceOrder',
//     },
// ];
