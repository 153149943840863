<template>
  <div class="navbar navbar-expand-md py-1 navbar--dark">
    <!-- Main navbar -->
    <div class="container p-0">
      <div class="navbar-brand text-left">
        <a href="/" class="d-inline-block">
          <h1 class=" font-weight-bolder logo__custom  ">
          {{site_settings.name }}
          </h1>
        </a>
      </div>

      <div class="d-md-none">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <i class="icon-paragraph-justify3"></i>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link v-if="userType == userTypes.admin"  class="navbar-nav-link pulse-button" :to="{ name: 'PlaceOrder' }"
              >Place Order</router-link
            >
          </li>

          <!-- <li class="nav-item">
            <router-link class="navbar-nav-link" :to="{ name: 'BulkOrder' }"
              >Bulk Order</router-link
            >
          </li> -->
          <li class="nav-item" v-if="isAuth">
            <router-link class="navbar-nav-link font-size-sm " :to="{ name: 'Dashboard' }"
              >Home</router-link
            >
          </li>
          <span v-if="isAuth">
            <li class="nav-item py-1">
              <button class="btn font--weight btn--secondary flex-row d-flex align-items-center justify-content-center px-3 py-2" @click="doLogout">
                <svg data-v-69911aa6="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path data-v-69911aa6="" d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"></path></svg>
                Logout
              </button>
            </li>
          </span>
          <li class="nav-item" v-if="!isAuth">
            <router-link class="navbar-nav-link font-size-sm" :to="{ name: 'Login' }"
              >Login</router-link
            >
          </li>

          <li class="nav-item" v-if="!isAuth">
            <router-link class="navbar-nav-link font-size-sm" :to="{ name: 'Register' }"
              >Register</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- /main navbar -->
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Nav",
  computed: {
    ...mapState("auth", ["isAuthenticated", "user"]),
    ...mapState("setups", ["site_settings"]),
    ...mapGetters("auth", ["userType", "userTypes"]),


    isAuth() {
      if (
        this.isAuthenticated &&
        this.user &&
        Object.keys(this.user).length > 0
      ) {
        return true;
      } else return false;
    },
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("setups", ["getSiteSettings"]),

    
  },
   
  mounted() {
    this.getSiteSettings()

  },
};
</script>

<style scoped>
.navbar--dark {
    background-color: #0F80DE!important;
} 

.pulse-button {
  position: relative;
  display: block;
  font-weight: 700;
  padding: 10px 32px !important;
  font-size: 18px;
  font-weight: light;
  font-family: "Trebuchet MS", sans-serif;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: -1px;
  color: #0f80de !important;
  border: none;
  border-radius: 6px !important;
  background: #FFD30B;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(255, 211, 11, 0.5);
}

.font-size-sm{
  color: white !important;
  font-size: 16px!important;
  font-weight: 600!important;
}
</style>
