<template>
  <!-- Page header -->
        <div class="page-header   my-2">
          <div class="page-header-content header-elements-md-inline">
            <div class="page-title d-flex align-items-center justify-content-between  w-100 py-2">
                <v-btn
                  color="primary"
                  outlined
                  
                  medium
                  dark
                  @click="$router.go(-1)"
                >
                <i class="icon-arrow-left52 has-pointer" ></i> 
                Back
                </v-btn>
                <h2>
                <span class="font-weight-bold mx-3 text-capitalize">{{title || ''}}</span>
                </h2>
            </div>
          </div>
        </div>
        <!-- /page header -->
</template>

<script>

export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: false,
    }
  }
}
</script>

<style>
  .has-pointer{
    cursor: pointer;
  }
  .page-header {
     border-radius: 8px !important;
}
.page-header-light {
    background-color: #fff;
    border-bottom:none !important; 
}
</style>