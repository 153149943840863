import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Globally register all `_base`-prefixed components
import "./components/index";
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
// stylings
import "./assets/styles/main.scss";

// only import the icons you use to reduce bundle size
import 'vue-awesome/icons/flag'

// or import all icons if you don't care about bundle size
import 'vue-awesome/icons'

/* Register component with one of 2 methods */

import Icon from 'vue-awesome/components/Icon'


// filters
require('./filters/index')


// for notifications
import VuePNotify from "vue-pnotify";
Vue.use(VuePNotify);

// for api calls
import http from "./modules/http.index";
Vue.prototype.$http = http;

import Request from "./modules/requests";
let req = new Request();
Vue.prototype.$req = req;

import { Form, HasError, AlertError } from "vform";
Vue.prototype.$Form = Form;
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

Vue.use(VueSweetalert2, options);

// globally (in your main .js file)
Vue.component('v-icon', Icon)



// vue editor / rich textarea
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

Vue.prototype.$event = new Vue();

Vue.prototype.$baseUrl = process.env.VUE_APP_API_BASE_URL

Vue.config.productionTip = false;

// Don't warn about using the dev version of Vue in development.
// Vue.config.productionTip = process.env.NODE_ENV === 'production'
import vuetify from './plugins/vuetify'

import './registerServiceWorker'

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
